<template>
  <div>
     <div class=" bj-color  work-title-all aligin-items-center padding-top">
        <div class="margin-left " style="width: 212px; height: 40px">
          <p>管辖校区在读学员绑定率：<span class="color-">0.84%</span></p>
          <el-progress :show-text="false" :percentage="0.84"></el-progress>
        </div>
      </div>
    <div class="flex bj-color padding">
      <div style="width: 25%; text-align: center">
        <div>新增家长数</div>
        <div><span>天</span><span>2</span></div>
        <div><span>周</span><span>2</span></div>
        <div><span>月</span><span>2</span></div>
      </div>
      <div
        style="
          width: 25%;
          text-align: center;
          border-left: 1px solid #cecece;
          border-right: 1px solid #cecece;
        "
      >
        <div>取消绑定家长数</div>
        <div><span>天</span><span>2</span></div>
        <div><span>周</span><span>2</span></div>
        <div><span>月</span><span>2</span></div>
      </div>
      <div
        style="width: 25%; text-align: center; border-right: 1px solid #cecece"
      >
        <div>净增家长数</div>
        <div><span>天</span><span>2</span></div>
        <div><span>周</span><span>2</span></div>
        <div><span>月</span><span>2</span></div>
      </div>
      <div style="width: 25%; text-align: center">
        <div><p>累计绑定家长数</p></div>
        <div>
          <div><span>69</span></div>
        </div>
      </div>
    </div>
    <div>
      <el-tabs type="border-card" style="margin-top: 10px">
        <el-tab-pane label="新增家长数" style="padding: 10px 20px">
          <div class="block">
            <el-select v-model="value" placeholder="请选择">
              <el-option label="近30天" value=""> </el-option>
              <el-option label="近7天" value="2"> </el-option>
              <el-option label="今天" value="3"> </el-option>
            </el-select>
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>

            <el-button type="primary" icon="el-icon-search">查询</el-button>
          </div>
          <el-row :gutter="20">
            <el-col :span="8">
              <echart
                width="1000px"
                :options="option"
                id="schoolCompleteRate_New"
              ></echart>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="取消绑定家长数" style="padding: 10px 20px">
          <div class="block">
            <el-select v-model="value" placeholder="请选择">
              <el-option label="近30天" value=""> </el-option>
              <el-option label="近7天" value="2"> </el-option>
              <el-option label="今天" value="3"> </el-option>
            </el-select>
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>

            <el-button type="primary" icon="el-icon-search">查询</el-button>
          </div>
          <el-row :gutter="20">
            <el-col :span="8">
              <echart
                width="1000px"
                :options="option2"
                id="schoolCompleteRate_New2"
              ></echart>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="净增家长数" style="padding: 10px 20px">
          <div class="block">
            <el-select v-model="value" placeholder="请选择">
              <el-option label="近30天" value=""> </el-option>
              <el-option label="近7天" value="2"> </el-option>
              <el-option label="今天" value="3"> </el-option>
            </el-select>
            <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>

            <el-button type="primary" icon="el-icon-search">查询</el-button>
          </div>
          <el-row :gutter="20">
            <el-col :span="8">
              <echart
                width="1000px"
                :options="option3"
                id="schoolCompleteRate_New3"
              ></echart>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import BarY from "@/components/echarts/BarY";
import Echart from "@/components/echarts/Echart";
export default {
  components: { BarY, Echart },
  data() {
    return {
      value: "",
      value1: "",

      option: {
        title: {
          text: "趋势图",
        },
        xAxis: {
          type: "category",
          data: [
            "2021-02-04",
            "2021-02-08",
            "2021-02-12",
            "2021-02-16",
            "2021-02-20",
            "2021-02-24",
            "2021-02-28",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [0, 0, 0, 0, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2],
            type: "line",
          },
        ],
      },  option3: {
        title: {
          text: "趋势图",
        },
        xAxis: {
          type: "category",
          data: [
            "2021-02-04",
            "2021-02-08",
            "2021-02-12",
            "2021-02-16",
            "2021-02-20",
            "2021-02-24",
            "2021-02-28",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [0, 1.6, 0, 0, 0, 1, 2, 0, 0, 1, 2],
            type: "line",
          },
        ],
      },  option2: {
        title: {
          text: "趋势图",
        },
        xAxis: {
          type: "category",
          data: [
            "2021-02-04",
            "2021-02-08",
            "2021-02-12",
            "2021-02-16",
            "2021-02-20",
            "2021-02-24",
            "2021-02-28",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [1, 0, 2, 0, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2],
            type: "line",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.bj-color {
  background-color: #fff;
}
.rules-list {
  min-height: 150px;
  padding: 0 10px;
  li {
    height: 35px;
    display: flex;
    justify-items: center;

    justify-content: space-between;
  }
}
</style>